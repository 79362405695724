.code-panel{
    background: #2b3046;

}
.code-panel .nav-tabs{
    padding-left: 0;
    background: #2b3046;
}
.code-panel .nav-item{
    padding: 0;
    height: 38px;
}

.code-panel .nav-tabs .nav-item {
    margin-bottom: 0;
}

.code-panel .nav-tabs .nav-link.active{
    color:white;
    background-color:#2b3046;
    border: 0;
    border-bottom: 4px solid #efce6f;
    font-size: .75rem;
}
.code-panel .nav-tabs .nav-link:focus,.code-panel  .nav-tabs .nav-link:hover{
    border: 0;
    border-bottom: 4px solid #efce6f;
}

.code-panel .nav-tabs .nav-link{
    color:#a9a4a4;
    background-color:#2b3046;
    border: 0;
    /*border-bottom: 4px solid #696766;*/
    font-size: .75rem;
}
.tab-pane pre{
    background :#2b3046 !important;
    border-radius: 0 !important;
    box-shadow : none !important;
    border: 0 !important;
    margin:0 !important;
    padding-top: 0px !important;
    padding-bottom: 1.5rem !important;
    text-shadow: none !important;
}
.tab-pane pre code{
    text-shadow: none !important;
}
.justify-content-end .nav-item{
    margin-top: -2.46rem;
}
.justify-content-end .nav-tabs .nav-link.copy.active{
    color:white;
    background-color:#2b3046;
    border: 0;
    font-size: .75rem;
}
.nav-tabs .copy:focus, .nav-tabs .copy:hover {
    border: 0 !important;
    border-bottom:0 !important;
}
.copy-margin{
    margin-left: 5px ;
    cursor: pointer;
}

.tab-content{
    max-height: 25rem;
    overflow: overlay;
}