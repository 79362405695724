
body{
    position: absolute;
    width :100%;
}
.site-logo .navbar-brand{
    font-size: 1rem;
}
.docs-branding {
    min-height: 50px;
    background: #3e53b7;
}
.site-logo .logo-text {
    color: white;
}
.social-list{
    margin-right: 0rem !important;
}
.docs-top-utilities {
    padding-top: 2px;
}
.search-form .search-input {
    font-size: 0.875rem;
    border-radius: 1.5rem;
    padding-right: 3rem;
    padding-left: 1.5rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    height: 1.25rem;
    background: #2a3e9c;
    border: #20317d solid 1px;
}
.docs-logo-wrapper {
    top: 0.3rem;
}

.docs-sidebar {
    display: block;
    width: 270px;
    position: fixed;
    z-index: 10;
    top: 50px;
    height: calc(100% - 50px);
    background: #fff;
    overflow-y: auto;
    scrollbar-color: rgba(0,0,0,0.2) #f5f5f5;
    scrollbar-width: thin;
    transition: transform 0.35s ease-in-out;
    box-shadow: 1px 0 5px 0 rgba(0,0,0,0.1);
}
.left-bar{
    left:0
}
.docs-nav .nav-link.active .theme-icon-holder {
    color: #fff;
    background: #3e53b7;
}
.docs-nav .nav-link.active {
    color: #5d6778;
}
.docs-nav .section-title {
    font-size: 1rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
}
.docs-nav .nav-item.section-title .nav-link {
    font-weight: 600;
}
.docs-content {
    margin-left: 269px;
    padding-bottom: 100px;
}
.docs-article {
    padding: 1rem;
    color: #5d6778;
    padding-top: 0;
}
.docs-nav {
    overflow-y: auto;
    padding: 1rem;
    padding-top: 2rem;
    /* height: 100%; */
}
.docs-article .docs-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    position: relative;
}
.docs-nav .nav-link.active:hover {
    color: #3e53b7;
}
a{
    color: #3e53b7;
}
a:hover{
    color: #3e53b7;
}
.docs-nav .nav-link.active:before {
    background-color: #3e53b7;
}
.docs-sidebar{
    box-shadow: none;
    position: sticky;
}
.docs-article {
    padding: 1rem;
    color: #000000;
}

.docs-article .section-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #252930;
}
body{
    font-size: .96rem;
    overflow-x: hidden;
}
code {
    background: #e6e6e6;
    color: #252930;
    padding: 0.15rem 0.375rem;
    border-radius: .25rem;
}
.pull-right{
    float: right;
    margin-top: 1rem;
}
.btn-primary {
    background: white;
    color: #3e53b7;
    border: 1px solid #3e53b7;
    font-weight: 400;
    padding: 0.3rem 1.25rem;
}
.btn-primary:hover,  .btn-primary:focus{
    color: #fff;
    background-color: #3e53b7;
    border-color: #3e53b7;
}
.col-lg-4{
    max-width: 30%;
}
.col-lg-8{
    max-width: 70%;
}
.right-container{
    padding-left: 3rem;
}
.docs-article .docs-section {
    padding: 2.4rem 0;
    border-bottom: 1px solid #e7e9ed;
}
.footer-container{
    background: #2b3046;
    padding: 15px;
    text-align: center;
}
.text-muted {
    color: white !important;
}
.section-heading .fa-link{
    padding-top: 4px;
    width: .6em;
    color: #a4a4a7;
    cursor: pointer;
}
.section-heading .fa-link:hover{
    color: #3e53b7;
}
.todo-app-img-container{
    border: 1px solid #dcdbdb;
}
.todo-app-img-container img{
    width: 100%;
}
.margin-top-small{
    margin-top:1rem;
}
.docs-nav .nav-item{
    margin-left:1rem
}

.docs-article .sub-section-heading {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #252930;
}
.right-button{
    width: 100%;
    text-align: right;
}
.navigation-text{
    color: gray;
}
.github-icon{
    font-size: 1.5rem;
} 
.push, .footer-container{
    min-height: 100%;
    height: 250px;
    z-index: 1000;
}
.docs-content{
    margin-top: -560px;
}

.footer-container .section-items a{
    color: white;
    float: left;
    cursor: pointer;
}
.copyright-text{
    margin-top: 10px;
}

.footer-container .github-icon{
    font-size:2rem;
    margin-left: 37px;
}

.btn-link{
    color: #3e53b7 !important;
    border: 1px solid #3e53b7;
    font-weight: 300;
}
.btn-link:hover{
    color: #3e53b7 !important;
}

.footer-container .section-items{
    padding-left:4rem;
}
.burger-icon{
    color: white;
    margin-right: 12px;
    font-size: 1.3rem;
    cursor: pointer;
    display:none;
}
.display-side-bar{
    display: block;
}

.accordion {
    margin: 2rem auto;
  }
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f3f3f3;
  }
  
  .accordion-title:hover {
    background-color: #eeeeee;
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background-color: white;
  }