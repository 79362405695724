.right.docs-sidebar{
    top: 5.5rem;
    width:225px;
    position: fixed;
    height: calc( 100% - 350px);
}
.right .docs-nav{
    padding: 0;
    padding-left: 0;
    height: auto;
}
.right .docs-nav .nav-item{
    margin-left: 0;
}
.right .docs-nav .nav-link {
    padding: .3rem 1.5rem;
}