@media (max-width: 769px) {
    .container {
        max-width: 750px;
        padding: 0;
        margin: 0;
    }
    .docs-sidebar{
        display: none;
    }
    .docs-content {
        margin-top: 0;
        margin-left: 0;
    }
    .page-container {
        max-width : 100%;
        padding: 0;
    }
    .col-right-container{
        display: none;
    }
    .footer-container .section-items {
        padding-left: 0;
    }
    .docs-article {
        padding: 1.5rem;
    }
    .burger-icon{
        display: inline;
    }
    .display-side-bar{
        display: block;
        width:100%;
    }

}

@media (max-width: 414px) {
    .btn-sandbox{
        margin-left: 0 !important;
        margin-top: 10px !important
    }
}